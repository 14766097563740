<template>
  <EditDialog
    v-loading="loading"
    width="50%"
    @closeFn="onCloseFn"
    :isReturn="true"
    v-if="isWinBidDialogShow"
    :isShow.sync="isWinBidDialogShow"
  >
    <div slot="title" class="title">是否中标</div>
    <div slot="content" class="content" style="min-height: 50px; padding: 0 25px">
      <!-- 单选框区域 -->
      <el-radio-group
        :value="formData.isWin"
        style="margin-bottom: 20px; padding: 0 20px"
        @input="onRadioChange"
      >
        <el-radio label="loser">未中标</el-radio>
        <el-radio label="winner"> 已中标 </el-radio>
      </el-radio-group>
      <!-- 已中标附件上传区域 -->
      <UploadList
        style="min-height: 500px"
        v-model="uploadData"
        fileCode="user_fj"
        v-if="formData.isWin == 'winner'"
        :staffId="formData.fkId"
      ></UploadList>
    </div>
    <div slot="footer" class="footer">
      <el-button type="primary" plain @click="onSaveFn">保存</el-button>
      <el-button type="info" plain @click="onCloseFn">返回</el-button>
    </div>
  </EditDialog>
</template>

<script>
export default {
  name: 'WinBidDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    UploadList: () => import('@/components/upload/ContractList.vue'),
  },
  props: {
    isWinBidDialogShow: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadData: [],
      //表单数据
      formData: {
        isWin: '',
        fkId: '',
      },
    }
  },
  watch: {
    detailData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.formData.isWin = newVal.isWin
        this.formData.fkId = newVal.fkId
        // 将详情中的附件数据传入uploadData
        this.getDetailFileList(newVal)
      },
    },
  },
  methods: {
    onCloseFn() {
      this.formData = {}
      this.uploadData = []
      this.$emit('update:isWinBidDialogShow', false)
    },

    onRadioChange(e) {
      this.formData.isWin = e
      if (this.uploadData.length > 0) {
        this.uploadData = []
      }
      this.$forceUpdate()
    },

    getDetailFileList(newVal) {
      if (newVal.fileList && newVal.fileList.length > 0) {
        let detailFileList = newVal.fileList.map(item => {
          return {
            attachmentName: item.fileName,
            attachmentPath: item.filePath,
            id: item.id,
            remark: item.remark,
            type: item.type,
            staffId: newVal.fkId,
            bidType: item.bidType,
          }
        })
        this.uploadData = [...detailFileList]
      }
    },

    onSaveFn() {
      // if (this.formData.isWin == 'winner' && this.uploadData.length == 0) {
      //   this.$message.warning('请上传附件')
      //   return
      // }
      //过滤出被删除的附件数组
      let deleteArr = (this.detailData.fileList || [])
        .filter(
          item =>
            !this.uploadData
              .filter(x => x.id)
              .map(y => y.id)
              .includes(item.id)
        )
        .map(item => item.id)
      let fileList = this.uploadData.map(item => {
        return {
          fkId: item.staffId,
          fileName: item.attachmentName,
          filePath: item.attachmentPath,
          id: item.id,
          remark: item.remark,
          type: 'ZBFJ',
          bidType: 'bidFile',
        }
      })
      this.formData.fileList = fileList
      this.formData.deleteFileList = deleteArr
      this.$emit('winBidSave', this.formData)
      this.onCloseFn()
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
